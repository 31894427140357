import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
  static ToVariableName(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    // Reemplaza cualquier carácter que no sea letra, número o subrayado con un subrayado
    let variableName = control.value.replace(/[^a-zA-Z0-9]/g, '_');

    // Si el primer carácter es un número, agrega un subrayado al inicio
    if (/^[0-9]/.test(variableName)) {
      variableName = '_' + variableName;
    }

    // Elimina subrayados consecutivos si los hubiera
    variableName = variableName.replace(/_+/g, '_');

    // Convierte todo el texto a minúsculas
    variableName = variableName.toLowerCase();

    // Compara el valor transformado con el original para determinar si hay errores
    if (variableName !== control.value.toLowerCase()) {
      return { invalidVariableName: true };
    }

    return null;
  }
}