import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { HttpErrorResponse } from "@angular/common/http";
import {
  ProcessType,
  SPlusDialogCloseResult,
  SPlusDialogSettings,
  SPlusMoreOption,
  SPlusOption,
} from "@saludplus/forms";

import { ConfiguracionConceptos, IConfiguracionConceptos } from "@src/app/models/Nomina/ConfiguracionConceptos.model";
import { ConfiguracionConceptosService } from "../services/configuracionConceptos.service";
import { ConfiguracionConceptosSettings } from "../config/configuracionConceptos.settings";
import { TerceroDTO } from "@src/app/models/Contabilidad/Terceros/TerceroDTO";

@Component({
  selector: "app-configuracionConceptos",
  templateUrl: "./configuracionConceptos.component.html",
  styleUrls: ["./configuracionConceptos.component.scss"],
})
export class ConfiguracionConceptosComponent extends SplusBaseForm implements OnInit, OnDestroy {

  // Forms
  configuracionConceptos: IConfiguracionConceptos;
  DatosConfiguracionConceptos: IConfiguracionConceptos;


  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/nomina/proceso/configuracion-conceptos/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearConfiguracionConceptos",
      showIn: ProcessType.view,
      text: "Crear nueva configuración",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarConfiguracionConceptos",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
  ];

  private unsubcribe$ = new Subject<void>();


  private service = inject(ConfiguracionConceptosService);
  private settings = inject(ConfiguracionConceptosSettings);



  constructor() {
    super()
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.title = this.route.snapshot.data['title'];
    this.icon = this.route.snapshot.data['icon'];
    this.titleService.setTitle(this.title);

    this.FormRecord = this.settings.FormComponents();


    this.FormRecord.disable();

    this.After();
 
  }


  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }


  ngOnDestroy() {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

  /**
   * Guardar o actualizar el registro
   */
  async onSubmit(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {
        this.UpdateRecord();
      } else {
        this.SaveRecord();
      }
    } else {
      this.isLoading = false;
    }
  }

  async thirdChange(data: TerceroDTO) {

  }

  /**
   * Guardar
   */
  SaveRecord() {
    this.service
      .Guardar<number, ConfiguracionConceptos>(this.FormRecord.value)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "i" },
              relativeTo: this.route,
            });
          }

        }
      });
  }

  /**
   * Actualizar
   */
  UpdateRecord() {
    this.isLoading = true;
    this.submitted = true;
    if (this.FormRecord.valid && !this.FormRecord.pristine) {
      let data = this.FormRecord.value as ConfiguracionConceptos;
      data.id = this.id;
      this.service
        .Actualizar<boolean, ConfiguracionConceptos>(data)
        .subscribe({
          next: (res) => {
            if (res) {
              this.router.navigate([], {
                queryParams: { mode: "u" },
                queryParamsHandling: "merge",
              });
            }
          },
          error: (error) => {
            console.error('Error al actualizar el registro:', error);
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    } else {
      this.isLoading = false;
    }
  }


  /**
   * Eliminar
   */
  DeleteRecord() {
    this.isDeleting = true;
    this.isLoading = true;
    const userSearchParams = {
      id: this.id
    };
    this.service.Eliminar<boolean>(userSearchParams).subscribe({
      next: (res) => {
        if (res) {
          this.isDeleting = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        }

      },
      error: (error) => {
        console.error('Error al eliminar el registro:', error);
        this.isDeleting = false;
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar este configuración de conceptos?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.splusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          //console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }


  checkedChange(event) {

    if (this.processType == ProcessType.view) return

    if (this.processType == ProcessType.edit) {

      let mensaje =  event == 'formula' ? ` Si continuas el valor actual será reemplazado por la nueva formula.` :
       `Si continuas la fórmula actual será reemplazada por el nuevo valor.`;

      let dialogSettings = {} as SPlusDialogSettings;
      dialogSettings.title = "Cambiar tipo de fórmula";
      dialogSettings.isHtml = true;
      dialogSettings.description =`¿Estas seguro que desea cambiar el tipo de fórmula? <br/>
         ${mensaje}`;
      dialogSettings.actions = [
        { text: "Cancelar" },
        { text: "Si, Cambiar", themeColor: "warning", value: true },
      ];
      this.splusFormsDialogsService.Show(dialogSettings).pipe(takeUntil(this.unsubcribe$))
        .subscribe((result) => {
  
          if (!result || !result['value'] || result['text'] == "Cancelar" || result instanceof SPlusDialogCloseResult) {
            
              if(event == 'valor'){  
                this.FormRecord.get('formulaSeleted').setValue('formula');
              }else if(event == 'formula'){ 
                this.FormRecord.get('formulaSeleted').setValue('valor');
              } 

          } else {
            if (result.value) {
              this.changeFormula(event)
            }
          }
        });

    }else{
      this.changeFormula(event);
    }


  }


  protected changeFormula(event) {
    if (event == 'valor') {
      this.FormRecord.get('constante').enable();
      this.FormRecord.get('constante').setValidators(Validators.required);
      this.FormRecord.get('constante').updateValueAndValidity();
      this.FormRecord.get('constante')['required'] = true;

      this.FormRecord.get('formula').reset();
      this.FormRecord.get('formula').updateValueAndValidity();
      this.FormRecord.get('formula').disable();
      this.FormRecord.get('formula')['required'] = false;

    } else if (event == 'formula') {

      this.FormRecord.get('formula').enable();
      this.FormRecord.get('formula').setValidators(Validators.required);
      this.FormRecord.get('formula').updateValueAndValidity();
      this.FormRecord.get('formula')['required'] = true;

      this.FormRecord.get('constante').reset();
      this.FormRecord.get('constante').updateValueAndValidity();
      this.FormRecord.get('constante').disable();
      this.FormRecord.get('constante')['required'] = false;
    }
 
 //   this.FormRecord.get('formulaSeleted').setValue(event);
  }


  /**
   * Limpiar formulario
   */
  clearForm() {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    // Valores por defecto
    this.SetDefaultValues();
    this.submitted = false;
  }
  /**
   * Setea valores por defecto
   */
  SetDefaultValues() {
 
    this.changeFormula(this.FormRecord.get('formulaSeleted').value)
  }

  /**
   * formulario en modo edicción
   */
  goEdit() {
    this.routingState.goEdit();
  }

  /**
   * Cancelar insercion o edición
   */
  cancelEdit() {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.splusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              //console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }

  nombreChange(evt) {
    if (this.f.nombre.value && !this.f.llave.value) {
      this.f.llave.setValue(this.toVariableName(this.f.nombre.value));
    }
  }

  toVariableName(text: string): string {
    // Reemplaza cualquier carácter que no sea letra, número o subrayado con un subrayado
    let variableName = text.replace(/[^a-zA-Z0-9]/g, '_');

    // Si el primer carácter es un número, agrega un subrayado al inicio
    if (/^[0-9]/.test(variableName)) {
      variableName = '_' + variableName;
    }

    // Elimina subrayados consecutivos si los hubiera
    variableName = variableName.replace(/_+/g, '_');

    // Retorna el nombre de variable en minúsculas
    return variableName.toLowerCase();
  }

  /**
   * Cargar el registro por su id
   */
  LoadRecord() {

    this.isLoading = true;
    const userSearchParams = {
      id: this.id
    };
    this.service.Buscar<ConfiguracionConceptos>(userSearchParams).subscribe({
      next: (res) => {
        this.isDeleting = false;
        if (res) {

          this.DatosConfiguracionConceptos = res;
          if (this.DatosConfiguracionConceptos) {
            this.FormRecord.patchValue(this.DatosConfiguracionConceptos);
            //this.FormRecord.get('terceroDefault').setValue(this.DatosConfiguracionConceptos.terceroDefault);

            //  this.f.llave.disable();

            if (this.DatosConfiguracionConceptos.constante) { 
              this.FormRecord.get('formulaSeleted').setValue('valor');
            }
            else if (this.DatosConfiguracionConceptos.formula) {
              this.FormRecord.get('formulaSeleted').setValue('formula'); 
            }
            else if (this.DatosConfiguracionConceptos.idQuery) {
              this.FormRecord.get('formulaSeleted').setValue('query'); 
            }

            this.changeFormula(this.FormRecord.get('formulaSeleted').value)
            this.isLoading = false;

          }
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar configuración de conceptos";
        dialogSettings.description =
          "Hubo un error al intentar cargar el configuración de conceptos por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.splusFormsDialogsService.Show(dialogSettings);
      },
    });
  }



  After() {
    this.FormRecord.enable();

    if (this.id) {
      this.LoadRecord();
    } else {
      this.SetDefaultValues();
    }
    this.isLoading = false;

  }



  /**
   * Cancelar edicion
   */
  cancelUpdate() {
    this.FormRecord.patchValue(this.DatosConfiguracionConceptos);
    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });

    if (this.DatosConfiguracionConceptos.constante) { 
      this.FormRecord.get('formulaSeleted').setValue('valor');
    }
    else if (this.DatosConfiguracionConceptos.formula) {
      this.FormRecord.get('formulaSeleted').setValue('formula'); 
    }
    else if (this.DatosConfiguracionConceptos.idQuery) {
      this.FormRecord.get('formulaSeleted').setValue('query'); 
    }

    this.changeFormula(this.FormRecord.get('formulaSeleted').value)
  }


}
