// formula-autocomplete.component.ts

import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { ConfiguracionConceptosService } from '../../services/configuracionConceptos.service';
import { ProcessType } from '@saludplus/forms';

interface FormulaOption {
    label: string;
    kind: 'funcion' | 'constante' | 'query';
    documentation: string;
    detail: string;
}

@Component({
    selector: 'app-formula-autocomplete',
    templateUrl: './formula-autocomplete.component.html',
    styleUrls: ['./formula-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FormulaAutocompleteComponent,
            multi: true
        }
    ]
})
export class FormulaAutocompleteComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    @Input() splusGroup: any;
    @Input() id: string;
    @Input() IdentificadorConcepto: string;
    public processType: ProcessType = ProcessType.create;
    public ProcessType = ProcessType;

    @ViewChild('formulaInput') formulaInput: TextBoxComponent;
    @ViewChild('optionsList') optionsList: ElementRef;

    formulaControl = new FormControl('');
    filteredOptions: FormulaOption[] = [];
    selectedIndex = -1;
    showTooltip = false;
    tooltipContent = '';
    tooltipPosition = { top: 0, left: 0 };

    private formulaOptions: FormulaOption[] = [
      /*  { label: 'Salario', kind: 'query', documentation: 'Salario base del empleado', detail: 'Ingresos' },
        { label: 'Impuesto', kind: 'funcion', documentation: 'Impuesto sobre la renta', detail: 'Deducciones' },
        { label: 'Descuento', kind: 'constante', documentation: 'Descuento por beneficios', detail: 'Deducciones' },
        { label: 'Bono', kind: 'constante', documentation: 'Bono por desempeño', detail: 'Ingresos' },
        { label: 'Horas_Extra', kind: 'funcion', documentation: 'Pago por horas extra trabajadas', detail: 'Ingresos' }*/
    ];

    configuracionConceptosService = inject(ConfiguracionConceptosService)
    constructor() { }

    writeValue(value: string): void {
        this.formulaControl.setValue(value, { emitEvent: true });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.formulaControl.disable() : this.formulaControl.enable();
    }

    onChange = (_: any) => { };
    private onTouched = () => { };

    onSplusGroupChange() {
        // Handle splusGroup changes here
    }

    ngOnInit() {
        this.formulaControl.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe(value => this.filterOptions(value));
    }

    ngAfterViewInit() {
        this.formulaInput.input.nativeElement.addEventListener('keydown', this.onKeyDown.bind(this));
        this.LoadFormulas();

    }

    async LoadFormulas() {
        this.formulaOptions = [];

        let formulas = await this.configuracionConceptosService.CargarFormulasAsync<any[]>();
        formulas.map(element => {

            if (this.IdentificadorConcepto != element.llave) {
                this.formulaOptions.push({
                    label: element.llave,
                    kind: element.tipoCalculo,
                    documentation: element.nombre,
                    detail: element.descripcion
                });
            }
        });
        //  this.formulaOptions = this.configuracionConceptosService.formulas;
        //console.log(formulas)
    }

    onKeyPress(event: KeyboardEvent) {
        if (event.keyCode === 13) { // Enter key
            event.preventDefault();
        }
    }

    onInput(event: any) {
        const textareaValue = event.target.value;
        const newValue = textareaValue.replace(/\n/g, ''); // Remove line breaks
        event.target.value = newValue;
    }



    selectedFormula: string;

    filterOptions(value: string) {
 
        if (value == null || this.processType == this.ProcessType.view) {         
            return;
        } 


        const filterValue = value.toLowerCase();
        const lastOperatorIndex = Math.max(
            filterValue.lastIndexOf('+'),
            filterValue.lastIndexOf('-'),
            filterValue.lastIndexOf('*'),
            filterValue.lastIndexOf('/'),
            filterValue.lastIndexOf('('),
            filterValue.lastIndexOf(')'),
            -1
        );

        if (lastOperatorIndex !== -1) {
            const formulaBeforeOperator = filterValue.substring(0, lastOperatorIndex).trim();
            const formulaAfterOperator = filterValue.substring(lastOperatorIndex + 1).trim();
            const cursorPosition = this.formulaInput.input.nativeElement.selectionStart;

            if (formulaBeforeOperator
                && formulaAfterOperator
                && formulaBeforeOperator !== this.selectedFormula
                && (this.selectedFormula && cursorPosition <= lastOperatorIndex + this.selectedFormula.length)) {
                this.filteredOptions = this.formulaOptions.filter(option =>
                    option.label.toLowerCase().includes(formulaAfterOperator)
                );
            } else {
                this.filteredOptions = [];
            }
        } else {
            this.filteredOptions = this.formulaOptions.filter(option =>
                option.label.toLowerCase().includes(filterValue)
            );
        }
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === ' ') {
            event.preventDefault();
            this.showAllOptions();
        } else if (this.filteredOptions.length > 0) {
            switch (event.key) {
                case 'ArrowDown':
                    event.preventDefault();
                    this.selectedIndex = Math.min(this.selectedIndex + 1, this.filteredOptions.length - 1);
                    this.scrollToSelectedOption();
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    this.selectedIndex = Math.max(this.selectedIndex - 1, -1);
                    this.scrollToSelectedOption();
                    break;
                case 'Enter':
                    event.preventDefault();
                    if (this.selectedIndex !== -1) {
                        this.selectOption(this.filteredOptions[this.selectedIndex]);
                    }
                    break;
                case 'Escape':
                    this.filteredOptions = [];
                    this.selectedIndex = -1;
                    break;
            }
        }
    }

    scrollToSelectedOption() {
        if (this.selectedIndex !== -1 && this.optionsList) {
            const selectedElement = this.optionsList.nativeElement.children[this.selectedIndex];
            if (selectedElement) {
                selectedElement.scrollIntoView({ block: 'nearest' });
            }
        }
    }

    showAllOptions() {
        this.filteredOptions = this.formulaOptions;
        this.selectedIndex = -1;
    }

    selectOption(option: FormulaOption) {
        const currentValue = this.formulaControl.value;
        const cursorPosition = this.formulaInput.input.nativeElement.selectionStart;
        const beforeCursor = currentValue.substring(0, cursorPosition);
        const afterCursor = currentValue.substring(cursorPosition);

        const lastOperatorIndex = Math.max(
            beforeCursor.lastIndexOf('+'),
            beforeCursor.lastIndexOf('-'),
            beforeCursor.lastIndexOf('*'),
            beforeCursor.lastIndexOf('/'),
            beforeCursor.lastIndexOf('('),
            beforeCursor.lastIndexOf(')'),
            -1
        );

        const newValue = beforeCursor.substring(0, lastOperatorIndex + 1) +
            (lastOperatorIndex === beforeCursor.length - 1 ? '' : ' ') +
            option.label + ' ' + afterCursor;

        this.formulaControl.setValue(newValue);
        this.onChange(newValue);
        this.selectedIndex = -1;

        this.selectedFormula = option.label;
        this.filteredOptions = []; // Vacía la lista de opciones filtradas después de asignar el valor de option.label a this.selectedFormula

        // Set cursor position after the inserted option
        setTimeout(() => {
            const newPosition = lastOperatorIndex + 1 + option.label.length + 1;
            this.formulaInput.input.nativeElement.setSelectionRange(newPosition, newPosition);
        });
    }

    showOptionTooltip(event: MouseEvent, option: FormulaOption) {
        this.tooltipContent = option.documentation;
        this.tooltipPosition = {
            top: event.clientY + 10,
            left:event.clientX + 10
        };

        if(this.tooltipContent)
        this.showTooltip = true;
    }

    hideTooltip() {
        this.showTooltip = false;
    }

    getIconForKind(kind: string): string {
        switch (kind) {
            case 'formula': return 'fa-duotone fa-solid fa-square-root-variable text-green-600 dark:text-green-400';
            case 'constante': return 'fa-duotone fa-solid fa-infinity text-blue-600 dark:text-blue-400';
            case 'calculoInterno': return 'fa-duotone fa-solid fa-arrow-progress text-purple-600 dark:text-purple-400';
            default: return 'code';
        }
    }
}