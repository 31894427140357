<!-- formula-autocomplete.component.html -->
<div class="relative">
    <kendo-textarea #formulaInput [formControl]="formulaControl" [id]="id" [splusGroup]="splusGroup"
        (valueChange)="onChange($event)" (input)="onInput($event)" (keypress)="onKeyPress($event)"  class="internal-ctrl whitespace-nowrap" 
        [rows]="3" wrap="off"></kendo-textarea>



    <kendo-popup [anchor]="formulaInput.input" *ngIf="formulaInput.value && filteredOptions.length > 0">
        <ul #optionsList class="w-96 max-h-60 overflow-y-auto border border-gray-300 rounded bg-white shadow-md">
            <li *ngFor="let option of filteredOptions; let i = index"
                class="p-2 cursor-pointer flex items-center justify-between hover:bg-gray-100"
                [class.bg-gray-200]="i === selectedIndex" (click)="selectOption(option)"
                (mouseover)="showOptionTooltip($event, option)" (mouseout)="hideTooltip()">
                <div class="flex items-center">
                    <i class="{{ getIconForKind(option.kind) }} w-5 text-center mr-2"></i>
                    <span>{{ option.label }}</span>
                    <!-- <span class="ml-2 text-gray-500">({{ option.kind }})</span>-->
                </div>
                <span class="text-gray-500">{{ option.detail }}</span>
            </li>
        </ul>
    </kendo-popup>

    <kendo-popup *ngIf="showTooltip" [offset]="tooltipPosition">
        <div class="bg-gray-100 border border-gray-300 p-2 rounded text-sm shadow-md" >
            {{ tooltipContent }}
        </div>
    </kendo-popup>
</div>

