import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AppSettings } from "@app/helpers/AppSettings";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component"; 
import { ConfiguracionConceptosSettings } from "./config/configuracionConceptos.settings";
import { ConfiguracionConceptosComponent } from "./components/configuracionConceptos.component";
import { ConfiguracionConceptosSearch } from "./config/configuracionConceptos.search";

const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Configuración Conceptos Listado" },
  },
  { path: "", component: ConfiguracionConceptosComponent },
  { path: ":id", component: ConfiguracionConceptosComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfiguracionConceptosRoutingModule {
 
  //Routing principal
  public static RoutingConfiguracionConceptos = {
    path: "nomina/proceso/configuracion-conceptos",
    loadChildren: () =>
      import("./configuracionConceptos.module").then(
        (m) => m.ConfiguracionConceptosModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Configuración Conceptos`,
      icon: "fa-regular fa-book-sparkles",
      setting: ConfiguracionConceptosSettings,
      search : ConfiguracionConceptosSearch
    },
  };
}
